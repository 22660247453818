<template>
  <div class="email-animation-container">
    <!-- Botón para enviar el email -->

    <!-- Animación de la carta siendo enviada -->
    <transition name="slide-up">
      <div v-if="sending" class="email-envelope">
        <svg
          width="100"
          height="100"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 6L12 13L22 6"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            x="2"
            y="6"
            width="20"
            height="14"
            rx="2"
            stroke="black"
            stroke-width="2"
          />
        </svg>
      </div>
    </transition>

    <!-- Animación de la verificación -->
    <transition name="fade">
      <div v-if="emailSent" class="email-sent-check">
        <svg
          width="100"
          height="100"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="10" stroke="#4CAF50" stroke-width="2" />
          <path
            d="M8 12L11 15L16 9"
            stroke="#4CAF50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </transition>

    <!-- Animación de error -->
    <transition name="fade">
      <div v-if="error" class="email-error">
        <svg
          width="100"
          height="100"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="10" fill="#f44336" />
          <path
            d="M12 8v4m0 4h.01"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
        <p>Error al enviar el correo</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sending: false, // Controla la animación de la carta
      emailSent: false, // Controla la aparición de la verificación
      error: false, // Controla la aparición del error
    };
  },
  watch: {
    status(newStatus) {
      if (newStatus === "sendEmail") {
        this.startSendingAnimation();
        this.emailSent = false;
        this.error = false; // Oculta el error si el estado es válido
      } else if (newStatus === "emailSent") {
        this.sending = false;
        this.emailSent = true;

        // Ocultar la verificación después de 3 segundos
        setTimeout(() => {
          this.emailSent = false;
          this.$emit("change-component", "SurveyTable");
        }, 3000);
      } else {
        this.sending = false;
        this.error = true; // Muestra el error si el estado no es válido

        setTimeout(() => {
          this.error = false;
          this.$emit("change-component", "SurveyTable");
        }, 3000);
      }
    },
  },
  methods: {
    startSendingAnimation() {
      this.sending = true;
      setTimeout(() => {
        this.sending = false; // Termina la animación
      }, 2000); // Duración de la animación (ajusta según sea necesario)

      // Reinicia la animación cada vez que se llama a este método
      this.$nextTick(() => {
        this.sending = true; // Vuelve a activar para la animación
      });
    },
  },
};
</script>

<style scoped>
/* Contenedor centrado */
.email-animation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Estilo del botón */
.send-email-button {
  padding: 10px 20px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.send-email-button:disabled {
  background-color: #b0bec5;
  cursor: not-allowed;
}

.send-email-button:hover:enabled {
  background-color: #1565c0;
}

/* Animación de la carta */
.email-envelope {
  width: 100px;
  height: 100px;
  margin-top: 20px;
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-200px);
    opacity: 0;
  }
}

.slide-up-enter-active {
  animation: slide-up 1s forwards;
}

/* Animación de la verificación */
.email-sent-check {
  margin-top: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Estilo del error */
.email-error {
  margin-top: 20px;
  text-align: center;
}

.email-error p {
  color: #f44336;
  margin-top: 10px;
}
</style>
