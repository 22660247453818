<template>
    <div class="dashboad-container">
        <iframe v-if="this.getUserName" allowfullscreen frameBorder="0" class="analytics-dashboard" :src="dashboardUrl"></iframe>
        <v-empty-state 
            v-if="!this.getUserName"
            headline=""
            title=""
            image=""
            class="empty-state-container"
        >
        <div class="state-elements">
            <img class="state-image" :src="imageSrc" alt="">
            <h2>No existe una compañia asociada a tu usuario</h2>
            <h3>Lo sentimos, por favor contacte al administrador</h3>
        </div>
        <!-- <div class="background-image">
            <img :src="imageSrc2" alt="">
        </div> -->
        </v-empty-state>
        
    </div>
</template>
<script>
  import { defineComponent } from 'vue';
  
  
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  
  export default defineComponent({
    name: 'AdminDashboard',
  
    components: {
    },
    data(){
      return {
        dashboardUrl:"",
        imageSrc: require("../assets/search_not_found.svg"),
        imageSrc2: require("../assets/blob_shape.svg"),
      }
    },
    mounted() {
        this.dashboardUrl = `https://analytics.zoho.com/open-view/2962073000000065871?ZOHO_CRITERIA=%22Clientes%22.%22Nombre%20de%20Cliente%22=%27${this.getUserName}%27`;
    },
    computed: {
    ...mapGetters(["getUserName"]),
    }
  })
</script>
<style>
.dashboad-container{
    width: 100%;
    height:100%;
    position:relative;
}
.analytics-dashboard{
    height:100%;
    width: 100%;
}
.state-elements{
    z-index:2;
    position: relative;
    h1{
        font-size: 5.0vh!important;
    }
    h2{
        font-size: 3.0vh!important;
    }
}
.state-image{
    height: 10%;
    width: 70%;
}
.background-image{
    position:absolute;
    top: 10%;
    left: 10%;
    z-index:1;
    
}
.background-image img{
    height: 800px;
    width: 800px;
}
.empty-state-container {
    color:#959faa;
}
</style>