<template>
  <div
    :class="{ 'app-container': true, 'modal-active': currentComponent != 'SurveyTable' }"
  >
    <v-app-bar v-if="smAndDown" class="bg-primary">
      <template v-slot:prepend>
        <v-app-bar-nav-icon @click="changeDrawer()"></v-app-bar-nav-icon>
      </template>
      <v-app-bar-title>{{ getTabName }}</v-app-bar-title>
      <v-spacer></v-spacer>
        <div>
          <v-btn @click="logout()" >
          <small><v-icon>mdi-logout-variant</v-icon>
          Cerrar Sesion</small>
        </v-btn>
        </div>
      
    </v-app-bar>
    <!-- <Sidebar route="surveys" @change-component="updateComponent"/> -->
    <v-navigation-drawer
      v-if="!smAndDown"
      floating
      v-model="tempDrawer"
      expand-on-hover
      rail
      rail-width="65"
      width="200"
      :permanent="!smAndDown"
      class="bg-primary sidebar-container"
    >
      <Sidebar
        class="sidebar-container"
        route="surveys"
        @change-component="updateComponent"
      />
    </v-navigation-drawer>
    <div :class="{ 'main-content': true, 'mobile-content': smAndDown }">
      <!-- Mostrar el indicador de carga mientras loading sea true -->
      <div v-if="loading" class="container">
        <div class="d-flex justify-center align-center flipping">
          <div class="flipping-text">GROW</div>
        </div>
      </div>

      <!-- Mostrar los componentes SearchBar y SurveyTable cuando loading sea false -->
      <div v-if="!loading && getTabName == 'Mis Encuestas'">
        <!-- <div v-if="!loading && getUserRole != 'company_admin'"> -->
        <SearchBar @search="filterSurveys" />
        <SurveyTable
          :ismobile="smAndDown"
          :completedSurveys="filteredCompletedSurveys"
          :pendingSurveys="filteredPendingSurveys"
        />
      </div>
      <div v-show="!loading && getTabName == 'Mi Dashboard'" class="dashboard-container">
        <!-- <div v-if="!loading && getUserRole == 'company_admin'" class="dashboard-container"> -->
        <AdminDashboard v-if="systemMounted && getUserRole == 'company_admin'" class="dashboard-element" />
      </div>
    </div>
    <EmailSent
      :status="currentComponent"
      @change-component="updateComponent"
      v-if="currentComponent != 'SurveyTable'"
      class="email-sent-popup"
    />
    <v-bottom-navigation
      v-if="smAndDown"
      v-model="tabMenuValue"
      bg-color="#0455b7"
      grow
      :active="tabMenuActive"
    >
      <v-btn>
        <v-icon>mdi mdi-format-list-checks</v-icon>

        Mis Encuestas
      </v-btn>

      <v-btn  v-if="getUserRole == 'company_admin'">
        <v-icon>mdi mdi-monitor-dashboard</v-icon>

        Mi Dashboard
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";

// Components
import Sidebar from "../components/sidebar.vue";
import SearchBar from "../components/SearchBar.vue";
import SurveyTable from "../components/SurveyTable.vue";
import AdminDashboard from "../components/AdminDashboard.vue";
import EmailSent from "../components/EmailSent.vue";

import axios from "axios";
import { mapGetters, mapActions, mapState } from "vuex";
import { useDisplay } from "vuetify";

export default defineComponent({
  name: "HomeView",
  components: {
    Sidebar,
    SearchBar,
    SurveyTable,
    AdminDashboard,
    EmailSent,
  },
  data() {
    return {
      tabMenuValue: 0,
      tabMenuActive: false,
      systemMounted: false,
      tempDrawer: true,
      loading: true,
      searchQuery: "",
      pendingSurveys: [],
      completedSurveys: [],
      currentComponent: "SurveyTable",
      // drawerM: false,
      // rail: true,
      // perma: false,
    };
  },
  async mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.updateTabName("Mis Encuestas");
    try {
      // this.updateDrawer(false);
      
      let userData = await this.fetchUsrData(this.getCrmId);

      let userFoundId = userData?.data?.recordMap?.data[0]?.id;
      if (!userFoundId) {
        this.showSnackbar(
          "Usuario no encontrado, porfavor contacte al administrador",
          "error",
          3000
        );
        this.loading = false;
        return;
      }

      this.updateUserName(userData?.data?.recordMap?.data[0]?.accountName || "");

      let getSurveys = await this.fetchSvyData(this.getCrmId);
      let surveysList = getSurveys?.data?.recordMap?.data;

      if (Array.isArray(surveysList) && surveysList?.length) {
        let redirectSurvey = localStorage.getItem("redirectSurvey") || "";
        for (let index = 0; index < surveysList.length; index++) {
          const currentSurvey = surveysList[index];
          //--------------------------------------------------------------------- Mapping of surveys records in "Pendientes" table
          let surveyObj = {
            id: currentSurvey.id,
            name: currentSurvey.name,
            sesion: currentSurvey.Sesion,
            dueDate:
              currentSurvey.stage == "Contestada"
                ? currentSurvey?.Hora_de_Completacion?.substring(0, 10)
                : currentSurvey.dueDate,
            stage: {
              name: currentSurvey.stage == "Pendiente" ? "Not-started" : "Completada",
              value: currentSurvey.stage,
            },
            department: currentSurvey.department,
            url:
              currentSurvey.stage == "Pendiente"
                ? `${currentSurvey.url}?survey=${currentSurvey.Sesion}&coach=${currentSurvey.Coach}&email=${currentSurvey.email}&usdId=${currentSurvey.id}`
                : currentSurvey.url,
          };
          if (currentSurvey.stage == "Pendiente") {
            if (redirectSurvey == currentSurvey.idEncuesta) {
              localStorage.removeItem("redirectSurvey");
              window.open(
                `${currentSurvey.url}?survey=${currentSurvey.Sesion}&coach=${currentSurvey.Coach}&usdId=${currentSurvey.id}`,
                "_self"
              );
              return;
            }
            this.pendingSurveys.push(surveyObj);
          }
          //--------------------------------------------------------------------- Mapping of surveys records in "Completadas" table
          else if (currentSurvey.stage == "Contestada") {
            this.completedSurveys.push(surveyObj);
          }
        }

        localStorage.removeItem("redirectSurvey");
      }
      this.systemMounted = true;
      this.loading = false;
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      this.showSnackbar("Error al obtener los datos", "error", 3000);
      this.loading = false;
      this.systemMounted = true;
      localStorage.removeItem("redirectSurvey");
    }
  },
  computed: {
    ...mapGetters([
      "getTabName",
      "getEmail",
      "getCrmId",
      "getUserRole",
      "drawer",
      "rail",
      "perma",
      "smAndDown",
    ]),
    ...mapState(["drawerM"]),
    filteredPendingSurveys() {
      return this.pendingSurveys.filter((survey) =>
        survey.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    filteredCompletedSurveys() {
      return this.completedSurveys.filter((survey) =>
        survey.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions([
      "triggerSnackbar",
      "toggleDrawer",
      "updateRail",
      "updatePerma",
      "updateSmAndDown",
      "updateDrawer",
      "updateUserName",
      "updateTabName",
    ]),
    async fetchUsrData(criteria) {
      try {
        const fetchData = await axios.post(`${process.env.VUE_APP_URL}/zoho/fetchUsrData`, 
          {          
            criteria: criteria,
            appToken: process.env.VUE_APP_TOKKEN,
          }
        );
        return fetchData;
      } catch (error) {
        console.log(error.message);
        return error;
      }
    },
    async fetchSvyData(criteria) {
      try {
        const fetchData = await axios.post(`${process.env.VUE_APP_URL}/zoho/fetchSvyData`, 
          {          
            criteria: criteria,
            appToken: process.env.VUE_APP_TOKKEN,
          }
        );
        return fetchData;
      } catch (error) {
        console.log(error.message);
        return error;
      }
    },
    filterSurveys(searchText) {
      this.searchQuery = searchText;
    },
    updateComponent(componentName) {
      this.currentComponent = componentName; // Cambia el componente actual
    },
    showSnackbar(message, color, timeout) {
      this.triggerSnackbar({
        message: message,
        color: color,
        timeout: timeout,
      });
    },
    handleResize() {
      const smAndDown = window.innerWidth < 800; // Ajusta el tamaño según tus necesidades
      this.updateSmAndDown(smAndDown); // Despacha la acción de Vuex
    },
    changeDrawer() {
      this.tabMenuActive = !this.tabMenuActive;
      // this.tempDrawer = !this.tempDrawer;
      // this.toggleDrawer(); // Usando la acción Vuex
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push({ name: "Login" });
    },
  },
  onBeforeUnmount() {
    // Limpia el listener al destruir el componente
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    tabMenuValue(newValue) {
      if (newValue == 0) {
        this.updateTabName("Mis Encuestas");
      } else if (newValue == 1) {
        this.updateTabName("Mi Dashboard");
      }
    },
    smAndDown(newValue) {
      if (newValue) {
        this.tabMenuValue = (this.getTabName == "Mis Encuestas" ? 0 : 1);
      }
    },
  },
});
</script>

<style scoped>
.app-container {
  display: flex;
  height: 100vh; /* Asegura que ocupe toda la ventana */
}

.modal-active::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo oscuro */
  z-index: 998;
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #f8f9fa;
  overflow-y: auto; /* Permitir el scroll vertical */
  box-sizing: border-box; /* Incluir padding en el cálculo de la altura */
}

.mobile-content {
  height: calc(100vh - 64px); /* Ocupa toda la altura disponible */
}
.container {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 100vh; /* Ocupar toda la altura de la ventana */
}

.flipping {
  width: 70.4px;
  height: 70.4px;
  background: #0455b7;
  transform: perspective(264px) rotateX(0);
  animation: flipping-animation 1.0499999999999998s infinite;
  border-radius: 10%;
}
.flipping-text {
  transform: perspective(264px) rotateX(0);
  animation: flipping-animation 1.0499999999999998s infinite;
}
@keyframes flipping-animation {
  50% {
    transform: perspective(264px) rotateX(180deg);
  }
  100% {
    transform: perspective(264px) rotateX(360deg);
  }
}

.email-sent-popup {
  position: fixed; /* Se queda fijo en la pantalla, fuera del flujo del documento */
  top: 50%; /* Centrado verticalmente */
  left: 50%; /* Centrado horizontalmente */
  transform: translate(-50%, -50%); /* Ajuste para centrar completamente */
  z-index: 999; /* Asegura que esté encima de todos los demás elementos */
  background-color: white; /* Fondo blanco para el popup */
  padding: 20px; /* Espaciado interior */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombras para destacar el popup */
  border-radius: 10px; /* Bordes redondeados */
  width: 300px; /* Ancho fijo para el popup */
  text-align: center;
  height: 300px;
}

.dashboard-container {
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.dashboard-element {
  position: absolute;
  top: 25px;
}

.sidebar-container {
  overflow: hidden;
}
</style>
