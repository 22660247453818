<template>
  <div id="container">
    <v-card id="expired-token-card">
      <img :src="imageSrc" alt="GROW" />
      <h1 class="mb-2 mt-2">Token Expirado</h1>
      <p class="mb-4">
        El enlace para cambiar tu contraseña ha expirado o es inválido. Por favor,
        solicita un nuevo enlace para restablecer tu contraseña.
      </p>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageSrc: require("../assets/GROW.png"),
    };
  },
  methods: {
    redirectToRequestPage() {
      // Aquí puedes redirigir al usuario a la página donde puede solicitar un nuevo enlace para restablecer su contraseña
      this.$router.push({ name: "RequestPasswordReset" });
    },
  },
};
</script>

<style scoped>
#expired-token-card {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  min-width: 350px;
  max-width: 500px;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.5) !important;
  text-align: center;
}

img {
  color: #4b7cb9;
}

.submit {
  background-color: #f6cb0b !important;
}

p {
  font-size: 16px;
  color: #333;
  margin-bottom: 1.5rem;
}
</style>
