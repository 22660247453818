<template >
    <LogIn />
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import LogIn from '@/components/LogIn.vue'; 

export default defineComponent({
  name: 'LoginView',

  components: {
    LogIn,
  },
   data: () => ({
    //
  }),
});
</script>

<style>

</style>
