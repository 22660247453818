<template>
  <div id="container" class="main-container">
    <v-card id="login" class="sub-container">
      <img :src="imageSrc" alt="GROW" />
      <h1 class="mb-2 mt-2 text-center">Inicio de Sesión</h1>
      <v-form class="form-container" ref="loginForm" @submit.prevent>
        <v-container fluid class="fill-height fields-container">
          <v-row>
            <v-col v-if="!loginWithCode" class="d-flex align-stretch flex-column">
              <v-text-field
                label="Correo electronico"
                v-model="username"
                variant="solo"
                type="email"
                :rules="[rules.email]"
                @keyup.enter="login"
                class="mb-2"
              >
              </v-text-field>
              <v-text-field
                label="Contraseña"
                v-model="password"
                variant="solo"
                :type="showPassword ? 'text' : 'password'"
                :append-inner-icon="
                  password ? (showPassword ? 'mdi-eye-off' : 'mdi-eye') : ''
                "
                @keyup.enter="login"
                @click:append-inner="togglePasswordVisibility"
                class=""
                :rules="[rules.password]"
              >
              </v-text-field>
              <small class="text-right"
                ><router-link
                  to="/recover-password"
                  class="text-center text-black ma-0 pa-0"
                  style="text-decoration: none"
                  >¿Olvidaste tu contraseña?</router-link
                ></small
              >
            </v-col>
            <v-col v-if="loginWithCode" class="d-flex align-stretch flex-column">
              <v-text-field
                :rules="[rules.code]"
                label="Codigo"
                v-model="userCode"
                variant="solo"
                type="text"
                @keyup.enter="login"
                class="mb-2"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-btn class="mb-5" block color="accent" @click="login" :loading="loading"
          >Iniciar sesión</v-btn
        >
        <div class="buttons-divider"><span>ó</span></div>
        <v-btn block color="blue" @click="changeLoginScreen()" :loading="loading">{{
          `Ingresar sesión con ${!loginWithCode ? "codigo" : "correo electronico"}`
        }}</v-btn>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loginWithCode: false,
      userCode: "",
      username: "",
      password: "",
      newUsername: "",
      newPassword: "",
      dashboardMessage: "",
      imageSrc: require("../assets/GROW.png"),
      showPassword: false,
      rules: {
        email: (value) => {
          const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return emailPattern.test(value)
            ? true
            : "Debes ingresar un correo electrónico válido";
        },
        password: (value) => (value ? true : "Debes ingresar una contraseña"),
        code: (value) => !!value || "Debes ingresar un codigo",
      },
      loading: false,
    };
  },
  methods: {
    changeLoginScreen() {
      this.username = "";
      this.password = "";
      this.userCode = "";
      this.loginWithCode = !this.loginWithCode;
    },
    ...mapActions(["triggerSnackbar", "toggleLoginScreen"]),
    async login() {
      this.loading = true;
      try {
        const valid = await this.$refs.loginForm.validate();
        if (!valid.valid) {
          this.loading = false;
          return;
        }
        if (this.loginWithCode) {
          // let encodedAppToken = window.btoa(process.env.VUE_APP_TOKKEN);
           const loginCodeRes = await axios.post(
            `${process.env.VUE_APP_URL}/login/compareCode`, 
            {
              code: this.userCode,
              appToken: process.env.VUE_APP_TOKKEN,
            },
          );
          // const loginCodeRes = await axios.get(
          //   `${process.env.VUE_APP_URL}/login/compareCode?code=${this.userCode}&appToken=${encodedAppToken}`
          // );
          const loginCodeToken = loginCodeRes?.data?.token;
          const loginCodeMessage = loginCodeRes?.data?.message;
          if (loginCodeMessage != "logged successfully" || !loginCodeToken) {
            this.showSnackbar("Codigo Invalido", "error", 3000);
            this.loading = false;
            return;
          }
          localStorage.setItem("token", loginCodeToken);
          this.$router.push({ name: "HomePage" });
          return;
        }
        const loginRequest = await axios.post(
          `${process.env.VUE_APP_URL}/login/comparePassword`,
          {
            email: this.username,
            password: this.password,
            appToken: process.env.VUE_APP_TOKKEN,
          }
        );
        const loginToken = loginRequest?.data?.token;
        const loginMessage = loginRequest?.data?.message;
        if (
          (loginMessage != "new password needed" &&
            loginMessage != "logged successfully") ||
          !loginToken
        ) {
          this.showSnackbar("Credenciales Incorrectas", "error", 3000);
          this.loading = false;
          return;
        }

        if (loginMessage == "new password needed" && loginToken) {
          this.$router.push({ path: "/change_password", query: { token: loginToken } });
          this.loading = false;
          return;
        }

        localStorage.setItem("token", loginToken);
        this.$router.push({ name: "HomePage" });
        this.loading = false;
      } catch (error) {
        this.showSnackbar("Hubo un error intenta de nuevo", "error", 3000);
        this.loading = false;
        console.error(error);
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // Alternar entre mostrar y ocultar la contraseña
    },
    showSnackbar(message, color, timeout) {
      this.triggerSnackbar({
        message: message,
        color: color,
        timeout: timeout,
      });
    },
  },
};
</script>

<style scope>
.main-container{}
#login {
  /* margin: 3rem auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%!important;
  min-width: 300px!important;
  max-width: 500px;
  /* padding: 40px; */
  background-color: rgba(64, 100, 59, 0.5) !important;
  height: 680px !important;
}
.form-container {
  height: 80% !important;
}
.fields-container {
  max-height: 65%;
}
.custom-link {
  color: var(--v-accent-base); /* Usar el color accent de Vuetify */
  text-decoration: none; /* Eliminar subrayado si no lo deseas */
}

.custom-link:visited {
  color: var(
    --v-accent-base
  ); /* Mantener el color accent cuando el enlace ha sido visitado */
}

.custom-link:hover {
  color: var(--v-accent-darken1); /* Cambiar el color al hacer hover */
  text-decoration: underline;
}

.buttons-divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.buttons-divider span {
  background: #82aadb;
  padding: 0 10px;
}
</style>
