import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
        email: '',
        crmId: "",
        userRole: "",
        userName: "",
        snackbar: {
          active: false,
          message: '',
          color: 'info',
          timeout: 3000,
        },
        loginWithCode:false,
        perma: false,
        drawerM: false,
        rail: true,
        smAndDown: false,
        changePwdToken:"",
        tabSelected:"",
    }
  },
  mutations: {
    changeLoginType(state, value){
        state.loginWithCode = value;
    },
    setPwdToken(state, value){
      state.changePwdToken = value;
    },
    setEmail(state, value){
        state.email = value;
    },
    setCrmId(state, value) {
      state.crmId = value;
    },
    setUserRole(state, value) {
      state.userRole = value;
    },
    setUserName(state, value) {
      state.userName = value;
    },
    setTabName(state, value) {
      state.tabSelected = value;
    },
    SHOW_SNACKBAR(state, { message, color, timeout }) {
        state.snackbar.active = true;
        state.snackbar.message = message;
        state.snackbar.color = color || 'info';
        state.snackbar.timeout = timeout || 3000;
    },
    HIDE_SNACKBAR(state) {
        state.snackbar.active = false;
        state.snackbar.message = '';
    },
    SET_DRAWER(state, value) {
      state.drawerM = value;
    },
    SET_RAIL(state, value) {
      state.rail = value;
    },
    SET_PERMA(state, value) {
      state.perma = value;
    },
    SET_SM_AND_DOWN(state, value) {
      state.smAndDown = value;
    }
  },
  actions: {
    toggleLoginScreen({ commit }, value){
      commit('changeLoginType', value);
    },
    updatePwdToken({ commit }, value){
      commit('setPwdToken', value);
    },
    updateEmail({ commit }, value){
        commit('setEmail', value);
    },
    updateCrmId({ commit }, value){
      commit('setCrmId', value);
    },
    updateUserRole({ commit }, value) {
      commit('setUserRole', value);
    },
    updateUserName({ commit }, value) {
      commit('setUserName', value);
    },
    updateTabName({ commit }, value) {
      commit('setTabName', value);
    },
    triggerSnackbar({ commit }, payload) {
        commit('SHOW_SNACKBAR', payload);
    },
    closeSnackbar({ commit }) {
        commit('HIDE_SNACKBAR');
    },
    toggleDrawer({ commit, state, getters }) {
      commit('SET_DRAWER', !state.drawerM);
    },
    updateRail({ commit }, value) {
      commit('SET_RAIL', value);
    },
    updatePerma({ commit }, value) {
      commit('SET_PERMA', value);
    },
    updateSmAndDown({ commit }, value) {
      commit('SET_SM_AND_DOWN', value);
    },
    updateDrawer({ commit }, value) {
      commit('SET_DRAWER', value)
    }
  },
  getters: {
    loginWithCode: state => state.loginWithCode,
    getPwdToken: state => state.changePwdToken,
    getEmail: state => state.email,
    getCrmId: state => state.crmId,
    getUserRole: state => state.userRole,
    getUserName: state => state.userName,
    getTabName: state => state.tabSelected,
    snackbar: state => state.snackbar,
    drawerM: state => state.drawerM,
    rail: state => state.rail,
    perma: state => state.perma,
    smAndDown: state => state.smAndDown,
    drawer: state => state.drawerM || !state.smAndDown,
  },
});

export default store;