<template>
  <v-app>
    <v-main :class="viewClass">
      <router-view/>
    </v-main>
    <v-container class="pa-0">
      <v-snackbar 
      :timeout="5000" 
      v-model="snackbar.active"
      :color="snackbar.color">
        {{ snackbar.message}}

        <template v-slot:actions>
        </template>
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(['snackbar']), // Accedemos al estado del snackbar desde Vuex
    viewClass() {
      switch (this.$route.name) {
        case 'home':
          return 'bg-primary main-background';
        case 'about':
          return 'about-background';
        case 'Login':
        case 'Change password':
        case 'Create password':
        case 'Recover password':
          return 'bg-primary main-background';
        default:
          return 'bg-primary main-background';
      }
    }
  }
}
</script>

<style scope>
v-main{
  height: 100%;
  overflow: hidden; 
}
.home-background {
  background-color: lightblue;
}
.about-background {
  background-color: lightgreen;
}
.login-background {
  background-color: var(--v-theme-primary);
}
.default-background {
  background-color: var(--v-theme-secondary);
  
}
.main-background {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' standalone='no'%3F%3E%3Csvg xmlns:xlink='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 625' preserveAspectRatio='xMaxYMax slice'%3E%3Cg transform='scale(0.8992805755395683)'%3E%3Crect x='0' y='0' width='1112' height='695' fill='%230455b7'/%3E%3Ccircle cx='34.75' cy='34.75' r='27.8' fill='%2318b6d3'/%3E%3Crect x='354.45' y='76.45' width='55.6' height='55.6' fill='%233c4ea5'/%3E%3Cg transform='translate(840.95,145.95) scale(2.7800000000000002) rotate(0)'%3E%3Crect width='20' height='10' fill='%233475c5'/%3E%3C/g%3E%3Cg transform='translate(910.45,145.95) scale(2.7800000000000002) rotate(0)'%3E%3Cpath d='M10 0L20 10L10 20L0 10L10 0Z' fill='%23434da2'/%3E%3C/g%3E%3Cg transform='translate(632.45,215.45) scale(2.7800000000000002) rotate(0)'%3E%3Crect width='20' height='10' fill='%230d82c4'/%3E%3C/g%3E%3Cg transform='translate(910.45,284.95) scale(2.7800000000000002) rotate(0)'%3E%3Crect width='20' height='10' fill='%237da7da'/%3E%3C/g%3E%3Cg transform='translate(423.95,354.45) scale(2.7800000000000002) rotate(0)'%3E%3Crect width='20' height='10' fill='%237a4690'/%3E%3C/g%3E%3Cpolygon points='771.45,354.45 827.0500000000001,354.45 827.0500000000001,410.05' fill='%23538ace'/%3E%3Crect x='771.45' y='423.95' width='55.6' height='55.6' fill='%231353b2'/%3E%3Ccircle cx='173.75' cy='590.75' r='27.8' fill='%231bc6d8'/%3E%3Cpolygon points='701.95,632.45 757.5500000000001,688.0500000000001 701.95,688.0500000000001' fill='%233878c6'/%3E%3C/g%3E%3C/svg%3E");
}
router-view {
}

#app {
  height: 100%; /* Asegúrate de que el contenedor principal de Vue también ocupe el 100% */
}
html, body {
  overflow: hidden; /* Elimina el scroll en todo el documento */
  height: 100%;     /* Asegura que el contenido ocupe el 100% de la ventana */
  margin: 0;
  padding: 0;
}
</style>
