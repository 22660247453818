<template>
  <div id="mainC">
    <div id="firstForm">sd</div>
    <div id="secondForm">cf</div>
    <v-container fluid class="fill-height text-container">
      <v-row >
         <v-col class="d-flex justify-center align-center flex-column" style='z-index:10;'>
            <h1 class="tittle">404</h1>
            <h2 class="subtittle">Oops... La pagina que buscas no existe</h2>
            <router-link to="/" class="button" style="text-decoration: none;">Pagina Principal <span class="mdi mdi-keyboard-return"></span></router-link>
         </v-col>
      </v-row>
   </v-container>
</div>
</template>
<style scoped>

  #firstForm {
    z-index:4;
    top:-400px;
    left:-445px;
    position:fixed;
     width: 1186px;
    height: 1190px;
    background: red;
    border-radius: 50%;
    background-color:#1f58c3;
     opacity: 0.8;
  }
  #secondForm {
    z-index:3;
    top:310px;
    right:-600px;
    position:fixed;
     width: 1400px;
    height: 1280px;
    background: red;
    border-radius: 50%;
    background-color:#1f58c3;
    overflow: hidden!important;
     opacity: 0.8;
  }
  #mainC {
    max-width: 340px;
    background-color:#0d49b9;
    position: relative;
    width: 100%;
    height: 100%;
    max-width:100%;
    margin: 0;
    overflow: hidden;
    overflow-x: hidden;
  }
  .text-container{
    /* background-color:purple; */
  }
  .tittle {
    margin: 0;
    padding: 0;
    font-size: 11em;
    transform: skewY(-5deg);
    transition: 0.4s ease-in-out all;
    &:hover {
      text-shadow:rgb(68, 207, 40) 5px 10px 0 ;
    }
    @media (max-width: 300px) {
      font-size: 50vw;
    }
  }
  .subtittle {
    font-size: 1.4em;
    font-weight: lighter;
    line-height: 1.4;
    transition: 0.2s ease-in-out all;
    &:hover {
      text-shadow:rgb(68, 207, 40) -2px 2px 0 ;
    }
    @media (max-width: 300px) {
      font-size: 5vw;
    }
  }
  .button{
    font-size: 1.4em;
    color:white;
    transition: 0.2s ease-in-out all;
    &:hover {
      text-shadow:rgb(5, 5, 5) 5px 5px 0 ;
    }
    @media (max-width: 300px) {
      font-size: 5vw;
    }
    /* -webkit-text-stroke: 0.2px black;  */
  }
</style>