<template>
  <Loader v-if="loading" />
  <ChangePassword :title="title" v-else-if="!error && !loading" />
  <TokenExpired v-else />
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";

import ChangePassword from "@/components/ChangePassword.vue";
import TokenExpired from "@/components/TokenExpired.vue";
import Loader from "@/components/Loader.vue";

import axios from "axios";

export default defineComponent({
  name: "PasswordView",

  components: {
    ChangePassword,
    TokenExpired,
    Loader,
  },
  data() {
    return {
      title: "Cambiar contraseña",
      error: false,
      loading: true,
    };
  },
  async mounted() {
    try {
      const queryToken = this.$route?.query?.token;
      if (!queryToken) {
        this.loading = false;
        this.error = true;
        return;
      }

      const validateToken = await axios.post(
        `${process.env.VUE_APP_URL}/login/verifyChangeToken`,
        {
          token: queryToken,
          appToken: process.env.VUE_APP_TOKKEN,
        }
      );

      if (validateToken?.data?.message != "valid token") {
        this.error = true;
      }
      this.loading = false;
    } catch (error) {
      console.log(error.message);
      this.loading = false;
      this.error = true;
    }
  },
});
</script>

<style></style>
