<template>
  <div class="sidebar bg-primary">
    <div>
      <div class="brand">
        <div class="logo-container">
          <img :src="imageSrc" alt="GROW" class="logo-element" />
        </div>

        <!-- <p>GROW</p> -->
      </div>
      <v-divider :thickness="3" class="border-opacity-75" color="white"></v-divider>
    </div>

    <div class="list-pages">
      <div class="tab-element">
        <div
          :class="getTabName == 'Mis Encuestas' ? 'tab-subelement top' : 'tab-subelement'"
        ></div>
      </div>
      <div class="tab-element button-pointer" @click="selectTab('Mis Encuestas')">
        <div :class="getTabName == 'Mis Encuestas' ? 'focused' : 'tab-subelement top'">
          <v-icon icon="mdi mdi-format-list-checks" class="mr-2 pr-3"></v-icon>
          Mis Encuestas
        </div>
      </div>
      <div
        class="tab-element button-pointer"
        v-if="getUserRole == 'company_admin'"
        @click="selectTab('Mi Dashboard')"
      >
        <div :class="getTabName == 'Mis Encuestas' ? 'tab-subelement bottom' : 'focused'">
          <v-icon icon="mdi mdi-monitor-dashboard" class="mr-2 pr-3"></v-icon>
          Mi Dashboard
        </div>
      </div>
      <div class="tab-element">
        <div
          :class="
            getTabName == 'Mis Encuestas'
              ? getUserRole != 'company_admin'
                ? 'tab-subelement bottom'
                : 'tab-subelement'
              : 'tab-subelement bottom'
          "
        ></div>
      </div>
      <!-- <v-list dense class="list bg-primary">
        <v-list-item
          v-for="(item, i) in pages"
          :key="i"
          :class="item?.active"
          @click="$router.push(item?.route)"
        >
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>

          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item>
      </v-list> -->
    </div>
    <v-menu location="end">
      <!-- <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="text"
              class="text-none btn-settings d-flex justify-start"
              prepend-icon="mdi mdi-cog-outline"
              block
              size="x-large"
            >
            Configuración
            </v-btn>
          </template> -->

      <v-list>
        <v-list-item v-for="(item, index) in settings" :key="index" @click="item.action">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <div class="bottom-menu">
      <v-divider :thickness="1" class="border-opacity-75 pb-3" color="white"></v-divider>
      <!-- <v-btn
        variant="text"
        class="text-none btn-settings d-flex justify-start"
        prepend-icon="mdi-logout"
        block
        size="x-large"
        @click="logout"
      >
        Cerrar sesión
      </v-btn> -->
      <div class="tab-element button-pointer">
        <div @click="logout()" class="tab-subelement text-h6">
          <small
            ><v-icon icon="mdi-logout-variant" class="mr-2 pr-4"></v-icon> Cerrar
            Sesión</small
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import axios from "axios";

export default {
  name: "Sidebar",
  props: {
    route: {
      type: String,
      required: true, // Esto es opcional, pero buena práctica
    },
  },
  data() {
    return {
      loadingTab: false,
      tabSeleccionada: "Mis Encuestas",
      surveyClass: "inactive",
      notificationClass: "bg-primary",
      pages: [],
      imageSrc: require("../assets/GROW.png"),
      showSettingsMenu: false,
      settings: [
        {
          title: "Cambiar contraseña",
          route: { name: "Change password" },
          action: this.sentEmail,
        },
      ],
    };
  },
  mounted() {
    // this.pages.push({ text: (this.getUserRole != 'company_admin' ? 'Mis encuestas' : 'Mi Dashboard') , icon: 'mdi mdi-format-list-checks', active: '', route: {name: 'HomePage'} });
    this.pages.push({
      text: "Mis encuestas",
      icon: "mdi mdi-format-list-checks",
      active: "",
      route: { name: "HomePage" },
    });
    this.pages.push({
      text: "Mi Dashboard",
      icon: "mdi mdi-monitor-dashboard",
      active: "",
      route: { name: "HomePage" },
    });
    // const ruta = this.route;
    // if(ruta == 'surveys'){
    //     this.pages[0].active = 'active';
    //     //this.pages[1].active = 'bg-primary';
    // }
    // if(ruta == 'notifications'){
    //     this.pages[1].active = 'active';
    //     //this.pages[0].active = 'bg-primary';
    // }
  },
  methods: {
    ...mapActions(["toggleDrawer", "updateTabName"]),
    logout() {
      localStorage.removeItem("token");
      this.$router.push({ name: "Login" });
    },
    async sentEmail() {
      try {
        this.toggleDrawer();
        this.$emit("change-component", "sendEmail");
        const email = this.getEmail;
        const res = await axios.post(`${process.env.VUE_APP_URL}/login/changePassword`, {
          email: email,
          appToken: process.env.VUE_APP_TOKKEN,
        });
        //this.$router.push({ name: 'Email Sent' , params: {test: 'test'}});
        this.$emit("change-component", "emailSent");
      } catch (err) {
        console.error("Error al obtener los datos:", err);
        this.$emit("change-component", "emailNotSent");
        this.showSnackbar("Hubo un error intenta de nuevo", "error", 3000);
        this.loading = false;
      }
    },
    selectTab(tabName) {
      // this.tabSeleccionada = tabName;
      this.updateTabName(tabName);
    },
  },
  computed: {
    ...mapGetters(["getEmail", "getUserRole", "getTabName"]),
  },
};
</script>

<style scoped>
.sidebar {
  width: 100%;
  color: rgb(255, 255, 255);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.brand {
  /* padding: 20px; */
  font-size: 24px;
  align-content: center;
  display: flex;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
}

ul {
  list-style: none;
  padding: 0;
}
.list {
  padding: 0;
  color: white;
  width: 100%;
}

li {
  padding: 10px 0;
  cursor: pointer;
}
.inactive {
  color: white;
}

.active {
  background-color: rgba(255, 255, 255, 0.877);
  color: black;
  padding: 10px;
  border-radius: 8px !important;
}

.bottom-menu {
  font-size: 18px;
  color: white;
  height: 75px;
}

a {
  text-decoration: none;
  color: white;
}

li.active a {
  color: black;
}

.logo-container {
  width: 60%;
}

.logo-element {
  width: 100%;
}

.list-pages {
  height: 100%;
  padding-right: 0px;
  margin: 0px;
  /* padding-top: 20px; */
  /* padding-bottom: 20px; */
}
.tab-element {
  /* background-color: v-bind(tabBackgroundColor); */
  background-color: #f8f9fa;
  margin-left: 20px;
  width: calc(100% - 19px);
  z-index: 10;
  height: 45px;
  border-radius: 30px 0% 0% 30px;
  line-height: 45px;
  transition-timing-function: ease;
  transition: all 0.4s;
  white-space: nowrap;
}
.tab-element:hover {
  margin-left: 10px;
  width: calc(100% - 9px);
}
.tab-element.button-pointer {
  cursor: pointer;
}
.tab-subelement {
  padding-left: 15px;
  background-color: #0455b7;
  width: 100%;
  height: 100%;
}
.focused {
  width: 100%;
  height: 100%;
  color: black;
  padding-left: 15px;
}
.tab-subelement.bottom {
  border-radius: 0 35px 0 0;
}
.tab-subelement.top {
  border-radius: 0 0 35px 0;
}
.corner-element {
  background-color: blue;
  height: 10px;
}

.logout {
  padding: 20px;
}
.settings {
  font-size: 18px;
  color: white;
  padding: 20px;
}

.settings-menu {
  background-color: rgb(183, 46, 4); /* Ajusta el color de fondo según tu diseño */
  color: white;
  padding: 10px 0;
}
.settings-menu .v-list-item {
  padding: 10px 20px; /* Espaciado interno para cada opción */
  cursor: pointer;
}
.settings-menu .v-list-item:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Efecto al pasar el mouse */
}
.btn-settings {
}
</style>
