import { createRouter, createWebHashHistory, createWebHistory  } from 'vue-router'
import store from '@/store';

import HomeView from '../views/HomeView.vue'
import Login from '../views/LoginView.vue'
import PasswordView from '@/views/ChangePasswordView.vue'
import RecoverPasswordView from '@/views/RecoverPasswordView.vue';
import NotFoundView from '@/views/NotFoundView.vue';

import axios from 'axios'
import EmailSentView from '@/views/EmailSentView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/change_password',
    name: 'Change password',
    component: PasswordView,
  },
  {
    path: '/recover-password',
    name: 'Recover password',
    component: RecoverPasswordView,
  },
  {
    path: '/home',
    name: 'HomePage',
    component: HomeView,
    meta: { requiresAuth: true }, // Solo accesible si el usuario está autenticado
  },
  {
    path: '/email-sent',
    name: 'Email Sent',
    component: EmailSentView,
    meta: { requiresAuth: true }, // Solo accesible si el usuario está autenticado
  },
  {
    path: '/:pathMatch(.*)*', //------------------------------------- Vista default, en caso de que no sea ninguna de las rutas anteriores
    name: 'Not Found',
    component: NotFoundView,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  let storedToken = localStorage.getItem('token');
  let redirectSurvey = to.query.svy || '';
  
  let isAuthenticated = false;
  //------------------------------------------------------------------- Store Survey ID to redirect
  if (redirectSurvey) {
    localStorage.setItem('redirectSurvey', redirectSurvey);
    delete to.query.svy;
    next({name: 'Login', query: to.query})
  }

  //------------------------------------------------------------------- Verify that Session Token is still valid
  if (storedToken) {
    try{
      const res = await axios.post(`${process.env.VUE_APP_URL}/login/verifyToken`, {
        jwt: storedToken,
        appToken: process.env.VUE_APP_TOKKEN
      });
      store.dispatch('updateCrmId', res?.data?.loggedCrmId)
      store.dispatch('updateUserRole', res?.data?.loggedRole);
      isAuthenticated = true;
      if (to.name == 'Login' || to.name == 'Change password' || to.name == 'Recover password') {
        next({name: 'HomePage'})
      }
    }catch(err){
      localStorage.removeItem('token');
      next({name: 'Login'});
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'Login' });
    return;
  }
  next();
  return;
});

export default router
