<template>
    <div class="app-container">
      <Sidebar route="" />
      <div class="main-content">
        <!-- Mostrar el indicador de carga mientras loading sea true -->  
        <!-- Mostrar los componentes SearchBar y SurveyTable cuando loading sea false -->
        <div>
          <EmailSent />
        </div>
      </div>
    </div>
</template>
  
<script>
  import { defineComponent } from 'vue';
  
  // Components
  import Sidebar from '../components/sidebar.vue';
  import SearchBar from '../components/SearchBar.vue';
  import SurveyTable from '../components/SurveyTable.vue';
  import EmailSent from '../components/EmailSent.vue';
  
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  
  export default defineComponent({
    name: 'HomeView',
  
    components: {
      Sidebar,
      SearchBar,
      SurveyTable,
      EmailSent
    },
    data(){
      return {
      }
    },
    mounted() {
    }
  })
</script>
  
<style scoped>
  .app-container {
    display: flex;
    height: 100vh; /* Asegura que ocupe toda la ventana */
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    background-color: #f8f9fa;
    overflow-y: auto; /* Permitir el scroll vertical */
    height: 100vh; /* Ocupa toda la altura disponible */
    box-sizing: border-box; /* Incluir padding en el cálculo de la altura */
  }
</style>